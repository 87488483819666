import Thompson1 from '../../../Media/KHI/Thomson/Thomson (1).webp'
import Thompson2 from '../../../Media/KHI/Thomson/Thomson (2).webp'
import Thompson3 from '../../../Media/KHI/Thomson/Thomson (3).webp'
import Thompson4 from '../../../Media/KHI/Thomson/Thomson (4).webp'
import Thompson5 from '../../../Media/KHI/Thomson/Thomson (5).webp'
import Thompson6 from '../../../Media/KHI/Thomson/Thomson (6).webp'
import Thompson7 from '../../../Media/KHI/Thomson/Thomson (7).webp'
import Thompson8 from '../../../Media/KHI/Thomson/Thomson (8).webp'
import Thompson9 from '../../../Media/KHI/Thomson/Thomson (9).webp'
import Thompson10 from '../../../Media/KHI/Thomson/Thomson (10).webp'


const Thompsonimages =[
Thompson1,Thompson2,Thompson3,Thompson4,Thompson5,Thompson6,Thompson7,Thompson8,Thompson9,Thompson10
]

export default Thompsonimages;