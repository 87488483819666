import Jasmia1 from '../../../Media/KHI/Jasmia/Jasmia (1).webp'
import Jasmia2 from '../../../Media/KHI/Jasmia/Jasmia (2).webp'
import Jasmia3 from '../../../Media/KHI/Jasmia/Jasmia (3).webp'
import Jasmia4 from '../../../Media/KHI/Jasmia/Jasmia (4).webp'
import Jasmia5 from '../../../Media/KHI/Jasmia/Jasmia (5).webp'
import Jasmia6 from '../../../Media/KHI/Jasmia/Jasmia (6).webp'
import Jasmia7 from '../../../Media/KHI/Jasmia/Jasmia (7).webp'
import Jasmia8 from '../../../Media/KHI/Jasmia/Jasmia (8).webp'
import Jasmia9 from '../../../Media/KHI/Jasmia/Jasmia (9).webp'
import Jasmia10 from '../../../Media/KHI/Jasmia/Jasmia (10).webp'
import Jasmia11 from '../../../Media/KHI/Jasmia/Jasmia (11).webp'
import Jasmia12 from '../../../Media/KHI/Jasmia/Jasmia (12).webp'



const Jasmiaimages =[
Jasmia1,Jasmia2,Jasmia3,Jasmia4,Jasmia5,Jasmia6,Jasmia7,Jasmia8,Jasmia9,Jasmia10,Jasmia11,Jasmia12
]

export default Jasmiaimages;